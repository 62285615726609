import IHandler from './IHandler';
import {
  CreateResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateParams,
} from 'react-admin';
import { getJson, putJson } from '../../../utils/api';

export default class Phone3DSHandler implements IHandler {
  static async getOneHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: GetOneParams<any>
  ): Promise<GetOneResult<RecordType>> {
    const id = params?.id;
    return getJson(`/admin/user/${id}/3ds-phone`, process.env.REACT_APP_BASE_CREDIT_CARD_ADMIN_API_URL).then(
      async (response) => {
        const { data } = await response.json();

        return {
          data: {
            id,
            phone: data,
          },
        } as any;
      }
    );
  }

  static async updateHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: UpdateParams<any>
  ): Promise<CreateResult<RecordType>> {
    const payload = params?.data || {};
    const { id } = payload;

    return putJson(`/admin/user/${id}/3ds-phone`, {}, process.env.REACT_APP_BASE_CREDIT_CARD_ADMIN_API_URL).then(
      async (response) => {
        const { data } = await response.json();
        data.id = data.userId;
        return {
          data: {
            ...data,
          },
        };
      }
    );
  }

  static async getMany<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    const { ids } = params;
    if (ids.length === 1) {
      const d = await this.getOneHandler(resource, { id: ids[0] });
      return { data: [d.data] };
    }
    return {
      data: [],
    };
  }

  static async sync(userId: string, onSuccess: () => void, onFailure: (err: string) => void) {
    return putJson(`/admin/user/${userId}/3ds-phone`, {}, process.env.REACT_APP_BASE_CREDIT_CARD_ADMIN_API_URL)
      .then(async () => {
        onSuccess();
        return {
          data: {
            id: userId,
          },
        };
      })
      .catch((response) => {
        onFailure(
          `${response.status}:${response.error?.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`
        );
        // do not show error if user does not have CreditCard account
        if (response.error?.errorMessage === 'There is no active card') {
          return Promise.resolve({
            data: {
              id: userId,
            },
          });
        } else {
          return Promise.reject({
            status: response.status,
            error: response.statusText,
            message: response.error?.message ?? response.error?.errorMessage,
          });
        }
      });
  }
}
