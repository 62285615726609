import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import dayjs from 'dayjs';
import UserListHandler from '../../react-admin/providers/handlers/UserListHandler';

const useDNC = ({ setShow, userId }: { setShow: (flag: boolean) => void; userId: string | undefined }) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState('');
  const [days, setDays] = useState<number>();

  const notify = useNotify();

  const refresh = useRefresh();
  const close = () => {
    setLoading(false);
    setShow(false);
  };
  const setDnc = async () => {
    setLoading(true);
    if (userId != null) {
      UserListHandler.updateHandler(
        userId,
        {
          id: userId,
          previousData: {},
          data: {
            doNotCallUntilDate: date,
          },
        },
        () => {
          refresh();
          close();
        },
        (e: any) => {
          notify(e.toString(), { type: 'error' });
          close();
        }
      );
    } else {
      close();
    }
  };

  const onSetDays = (event: any) => {
    setDays(event.target.value);
    setDate(dayjs().add(parseInt(event.target.value), 'day').format('YYYY-MM-DD'));
  };

  return {
    date,
    setDate: (event: any) => setDate(event.target.value),
    unlock: setDnc,
    close,
    loading,
    days,
    setDays: onSetDays,
  };
};

export const DoNotCallAction = ({ user }: { user: any }) => {
  const [show, setShow] = useState(false);
  const { unlock, close, loading, date, setDate, days, setDays } = useDNC({
    setShow,
    userId: user.id,
  });
  const isDncActual = user.doNotCallUntilDate && dayjs().isBefore(dayjs(user.doNotCallUntilDate));
  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        {isDncActual && <strong>Do Not Call until {user.doNotCallUntilDate}</strong>}
        {!isDncActual && <>Do Not Call</>}
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Cancel">
        <DialogTitle>Do Not Call</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <span>
              <b>Attention!</b>
              <br />
              <br />
              You are about to set Do Not Call date for the customer.
              <br />
              <br />
              Are you sure you want to proceed?
            </span>
          </Grid>
          <Grid container={true} spacing={2}>
            <Grid item xs={6}>
              <div>Date until</div>
            </Grid>
            <Grid item xs={6}>
              Days
            </Grid>
          </Grid>
          <Grid container={true} spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="date"
                placeholder="Date until"
                variant="outlined"
                value={date}
                onChange={setDate}
                type={'date'}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="days"
                placeholder="Days"
                variant="outlined"
                value={days}
                onChange={setDays}
                type={'number'}
                InputProps={{ inputProps: { min: 0, max: 30 } }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>No</span>
          </Button>
          <Button onClick={unlock} disabled={date === '' || loading}>
            <IconSave />
            <span>Yes</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
