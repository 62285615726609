import { BooleanInput, Edit, SelectArrayInput, SimpleForm, TopToolbar } from 'react-admin';
import { BackButton } from '../../react-admin/components/BackButton';

const Actions = () => {
  return (
    <TopToolbar>
      <BackButton key={'back'} />
    </TopToolbar>
  );
};
export const AuthStateEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect={false} actions={<Actions />}>
      <SimpleForm>
        <BooleanInput source="MFAEnabled" label="MFAEnabled" />
        <SelectArrayInput
          source="allowedMFATypes"
          label="allowedMFATypes"
          choices={[
            { id: 'SMS_MFA', name: 'SMS_MFA' },
            { id: 'EMAIL_VERIFICATION', name: 'EMAIL_VERIFICATION' },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};
