import { Box, Button } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';

type CommentEditorProps = {
  text: string;
  onChange: (value: string) => void;
  selectedTab: 'write' | 'preview';
  onTabChange: (tab: 'write' | 'preview') => void;
  onSave: () => void;
  onCancel: () => void;
};

export const CommentEditor: React.FC<CommentEditorProps> = ({
  text,
  onChange,
  selectedTab,
  onTabChange,
  onSave,
  onCancel,
}) => (
  <Box flexDirection="column" gap={2} style={{ marginBottom: 30 }}>
    <ReactMde
      value={text}
      onChange={onChange}
      selectedTab={selectedTab}
      onTabChange={onTabChange}
      generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)}
    />
    <Box display="flex" gap={2}>
      <Button variant="contained" onClick={onSave} disabled={!text.trim()}>
        Save
      </Button>
      <Button variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
    </Box>
  </Box>
);
