import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import IconCancel from '@mui/icons-material/Cancel';
import IconSave from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import AuthStateHandler from '../../react-admin/providers/handlers/AuthStateHandler';

const useResetPassword = ({
  setShow,
  userId,
  email,
}: {
  setShow: (flag: boolean) => void;
  userId: string | undefined;
  email: string | undefined;
}) => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const refresh = useRefresh();
  const close = () => {
    setLoading(false);
    setShow(false);
  };
  const resetPassword = async () => {
    setLoading(true);
    if (userId != null && email != null) {
      AuthStateHandler.resetPassword(
        userId,
        email,
        () => {
          refresh();
          close();
          notify(`Action executed successfully.`, { type: 'success' });
        },
        (e: any) => {
          notify(e.toString(), { type: 'error' });
          close();
        }
      );
    } else {
      close();
    }
  };

  return {
    resetPassword,
    close,
    loading,
  };
};

export const ResetPasswordAction = ({ user }: { user: any }) => {
  const [show, setShow] = useState(false);
  const { resetPassword, close, loading } = useResetPassword({
    setShow,
    userId: user.id,
    email: user.email,
  });
  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        Reset Password
      </Button>
      <Dialog fullWidth open={show} onClose={setShow} aria-label="Reset Password">
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          Are you sure you want to reset the user's password?
          <p>
            The system will automatically send a password reset email, and the user's login access will be disabled
            until the password is changed.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={loading}>
            <IconCancel />
            <span>Close</span>
          </Button>
          <Button onClick={resetPassword} disabled={loading}>
            <IconSave />
            <span>Yes</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
