import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';

import { Comment } from '../../utils/models';
import ReactMarkdown from 'react-markdown';

type CommentItemProps = {
  comment: Comment;
};

export const CommentViewItem: React.FC<CommentItemProps> = ({ comment }) => {
  const [showAbsoluteDate, setShowAbsoluteDate] = useState(false);

  const displayDate = showAbsoluteDate
    ? DateTime.fromISO(comment.timestamp).toLocaleString(DateTime.DATETIME_SHORT)
    : DateTime.fromISO(comment.timestamp).toRelative();

  const handleDateClick = () => {
    setShowAbsoluteDate((prev) => !prev);
  };

  return (
    <Box key={comment.id} mb={2}>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary">
          <strong>{comment.userName}</strong> {'  '}
          <span style={{ cursor: 'pointer', color: 'blue' }} onClick={handleDateClick}>
            {displayDate}
          </span>
        </Typography>
        <ReactMarkdown>{comment.text}</ReactMarkdown>
      </Box>
    </Box>
  );
};
