import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Cancel, Edit as EditIcon } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import { DateTime } from 'luxon';
import { CommentEditor } from './CommentEditor';

import { Comment } from '../../utils/models';

type CommentItemProps = {
  comment: Comment;
  isEditing: boolean;
  editedText: string;
  selectedTab: 'write' | 'preview';
  onEditClick: () => void;
  onRemoveClick: () => void;
  onTextChange: (value: string) => void;
  onTabChange: (tab: 'write' | 'preview') => void;
  onSave: () => void;
  onCancel: () => void;
};

export const CommentEditItem: React.FC<CommentItemProps> = ({
  comment,
  isEditing,
  editedText,
  selectedTab,
  onEditClick,
  onRemoveClick,
  onTextChange,
  onTabChange,
  onSave,
  onCancel,
}) => {
  const [showAbsoluteDate, setShowAbsoluteDate] = useState(false);

  const displayDate = showAbsoluteDate
    ? DateTime.fromISO(comment.timestamp).toLocaleString(DateTime.DATETIME_SHORT)
    : DateTime.fromISO(comment.timestamp).toRelative();

  const handleDateClick = () => {
    setShowAbsoluteDate((prev) => !prev);
  };

  return (
    <Box key={comment.id} mb={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" color="textSecondary">
          <strong>{comment.userName}</strong> {'  '}
          <span style={{ cursor: 'pointer', color: 'blue' }} onClick={handleDateClick}>
            {displayDate}
          </span>
        </Typography>
        {!isEditing && comment.isDraft && (
          <Box display="flex">
            <IconButton size="small" onClick={onEditClick} aria-label="edit comment">
              <EditIcon />
            </IconButton>
            <IconButton size="small" onClick={onRemoveClick} aria-label="remove comment">
              <Cancel />
            </IconButton>
          </Box>
        )}
      </Box>

      {isEditing ? (
        <CommentEditor
          text={editedText}
          onChange={onTextChange}
          selectedTab={selectedTab}
          onTabChange={onTabChange}
          onSave={onSave}
          onCancel={onCancel}
        />
      ) : (
        <ReactMarkdown>{comment.text}</ReactMarkdown>
      )}
    </Box>
  );
};
