import React from 'react';
import { Box, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { CommentViewItem } from './CommentViewItem';
import { Comment } from '../../utils/models';

export const CommentsView = (props: { source: string; label?: string; className?: string; emptyText?: string }) => {
  const { source, label, emptyText } = props;
  const record = useRecordContext(props);
  const comments = record ? (record[source] as Comment[]) : null;

  return (
    <Box>
      <Typography variant="h6">{label ?? 'Comments'}</Typography>
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          border: '1px solid #ddd',
          padding: 2,
          maxHeight: 1000,
        }}
      >
        {comments && comments.length > 0 ? (
          comments.map((comment: Comment) => <CommentViewItem key={comment.id} comment={comment} />)
        ) : (
          <Typography variant="body2" color="textSecondary">
            {emptyText || 'No comments yet.'}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
