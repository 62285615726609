import IHandler from './IHandler';
import { GetOneParams, GetOneResult, RaRecord, UpdateParams, UpdateResult } from 'react-admin';
import { getJson, postJson } from '../../../utils/api';

export default class AuthStateHandler implements IHandler {
  static prevNextToken: Record<number, string> = {};
  static route = '/admin';
  static resourceIdName = 'userId';

  static async getOneHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const id = params?.id;
    return getJson(`${this.route}/auth/${id}/state`, process.env.REACT_APP_BASE_AUTH_ADMIN_API_URL).then(
      async (response) => {
        const { data } = await response.json();

        const d = data;

        d.id = d[this.resourceIdName];
        delete d[this.resourceIdName];
        return {
          data: {
            ...d,
          },
        };
      }
    );
  }

  static async updateHandler<RecordType extends RaRecord = any>(
    resource: string,
    updateParams: UpdateParams
  ): Promise<UpdateResult<RecordType>> {
    const { id } = updateParams;
    const groups = updateParams.data;
    return postJson(`${this.route}/auth/${id}/state`, groups, process.env.REACT_APP_BASE_AUTH_ADMIN_API_URL).then(
      async (response) => {
        const { data } = await response.json();

        const result = {
          ...data,
        };
        result.id = data['userId'];
        delete result[this.resourceIdName];
        return {
          data: {
            ...result,
          },
        };
      }
    );
  }

  static async resetPassword(userId: string, email: string, onSuccess: () => void, onFailure: (err: string) => void) {
    return postJson(
      `${this.route}/auth/${userId}/reset-password`,
      {
        email,
      },
      process.env.REACT_APP_BASE_AUTH_ADMIN_API_URL
    )
      .then(async () => {
        onSuccess();
        return {
          data: {
            id: userId,
          },
        };
      })
      .catch((response) => {
        onFailure(
          `${response.status}:${response.error.message ?? JSON.stringify(response.error, null, 2)}:${response.message}`
        );
        return Promise.reject({
          status: response.status,
          error: response.statusText,
          message: response.statusText,
        });
      });
  }
}
