import { useInput, CommonInputProps } from 'react-admin';
import { Box, Typography, IconButton, Badge } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import React, { useReducer } from 'react';
import { CommentEditItem } from './CommentEditItem';
import { CommentEditor } from './CommentEditor';
import { useGetUserId } from '../../react-admin/providers/cognitoAuthProvider';
import { Comment } from '../../utils/models';

const initialState = {
  isEditorVisible: false,
  newComment: '',
  selectedTab: 'write' as 'write' | 'preview',
  editingCommentId: null as string | null,
  editedText: '',
  commentsChanged: false,
};

type Action =
  | { type: 'TOGGLE_EDITOR' }
  | { type: 'SET_NEW_COMMENT'; value: string }
  | { type: 'SET_EDITED_TEXT'; value: string }
  | { type: 'SET_SELECTED_TAB'; tab: 'write' | 'preview' }
  | { type: 'COMMENTS_CHANGED' }
  | { type: 'RESET_EDITOR' }
  | { type: 'SET_EDITING_COMMENT'; id: string; text: string }
  | { type: 'CANCEL_EDIT' };

function reducer(state: typeof initialState, action: Action) {
  switch (action.type) {
    case 'TOGGLE_EDITOR':
      return { ...state, isEditorVisible: !state.isEditorVisible };
    case 'SET_NEW_COMMENT':
      return { ...state, newComment: action.value };
    case 'SET_EDITED_TEXT':
      return { ...state, editedText: action.value };
    case 'SET_SELECTED_TAB':
      return { ...state, selectedTab: action.tab };
    case 'COMMENTS_CHANGED':
      return { ...state, commentsChanged: true };
    case 'RESET_EDITOR':
      return { ...state, newComment: '', isEditorVisible: false };
    case 'SET_EDITING_COMMENT':
      return { ...state, editingCommentId: action.id, editedText: action.text };
    case 'CANCEL_EDIT':
      return { ...state, editingCommentId: null };
    default:
      return state;
  }
}

export const CommentsInput: React.FC<CommonInputProps> = (props) => {
  const {
    field,
    fieldState: { error, isTouched },
    isRequired,
  } = useInput(props);

  const userId = useGetUserId()!;

  const comments = field.value || [];

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleAddComment = () => {
    if (state.newComment.trim()) {
      const newCommentEntry: Comment = {
        id: crypto.randomUUID(),
        userId,
        userName: 'You',
        text: state.newComment,
        timestamp: new Date().toISOString(),
        isDraft: true,
      };
      field.onChange([newCommentEntry, ...comments]);
      dispatch({ type: 'RESET_EDITOR' });
      dispatch({ type: 'COMMENTS_CHANGED' });
    }
  };

  const handleEditComment = (id: string) => {
    if (state.editedText.trim()) {
      const updatedComments = comments.map((comment: Comment) =>
        id === comment.id ? { ...comment, text: state.editedText } : comment
      );
      field.onChange(updatedComments);
      dispatch({ type: 'COMMENTS_CHANGED' });
      dispatch({ type: 'CANCEL_EDIT' });
    }
  };

  const handleRemoveComment = (id: string) => {
    const updatedComments = comments.filter((comment: Comment) => id !== comment.id);
    field.onChange(updatedComments);
    dispatch({ type: 'COMMENTS_CHANGED' });
  };

  const renderComment = (comment: Comment) => {
    const isEditing = state.editingCommentId === comment.id;

    return (
      <CommentEditItem
        key={comment.id}
        comment={comment}
        isEditing={isEditing}
        editedText={state.editedText}
        selectedTab={state.selectedTab}
        onEditClick={() => dispatch({ type: 'SET_EDITING_COMMENT', id: comment.id, text: comment.text })}
        onRemoveClick={() => handleRemoveComment(comment.id)}
        onTextChange={(value) => dispatch({ type: 'SET_EDITED_TEXT', value })}
        onTabChange={(tab) => dispatch({ type: 'SET_SELECTED_TAB', tab })}
        onSave={() => handleEditComment(comment.id)}
        onCancel={() => dispatch({ type: 'CANCEL_EDIT' })}
      />
    );
  };

  return (
    <Box>
      <Box display="flex" gap={2}>
        <Badge
          color="secondary"
          variant="dot"
          invisible={!state.commentsChanged}
          sx={{
            '& .MuiBadge-dot': {
              right: '-4px',
            },
          }}
        >
          <Typography variant="h6">
            {props.label ?? 'Comments'}
            {isRequired && ' *'}
          </Typography>
        </Badge>
        <IconButton onClick={() => dispatch({ type: 'TOGGLE_EDITOR' })} sx={{ mt: -0.5 }}>
          <AddCircle />
        </IconButton>
      </Box>

      {state.isEditorVisible && (
        <CommentEditor
          text={state.newComment}
          onChange={(value) => dispatch({ type: 'SET_NEW_COMMENT', value })}
          selectedTab={state.selectedTab}
          onTabChange={(tab) => dispatch({ type: 'SET_SELECTED_TAB', tab })}
          onSave={handleAddComment}
          onCancel={() => dispatch({ type: 'TOGGLE_EDITOR' })}
        />
      )}

      {comments.map(renderComment)}

      {isTouched && error?.message && (
        <Typography variant="body2" color="error">
          {error.message}
        </Typography>
      )}
    </Box>
  );
};
