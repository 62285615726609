import {
  BooleanField,
  EditButton,
  Empty,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useResourceAccess } from '../../hooks/useAccess';

const Actions = () => {
  const { canEdit } = useResourceAccess();
  if (!canEdit()) {
    return <></>;
  }

  return (
    <TopToolbar>
      <EditButton key="edit" />
    </TopToolbar>
  );
};

export const createDateRenderer = (column: string) => (record: any) =>
  record[column] && dayjs(record[column]).format('YYYY-MM-DD HH:mm');

export const AuthStateDetailsForm = () => (
  <Grid container spacing={2}>
    <Grid xs={6} item>
      <SimpleShowLayout>
        <BooleanField source="MFAEnabled" label={'MFAEnabled'} />
        <TextField source="allowedMFATypes" label={'allowedMFATypes'} />
        <FunctionField source="lastActivity" render={createDateRenderer('lastActivity')} label={'lastActivity'} />
        <FunctionField source="lastLoggedIn" render={createDateRenderer('lastLoggedIn')} label={'lastLoggedIn'} />
        <FunctionField
          source="lastPasswordChangeRequest"
          render={createDateRenderer('lastPasswordChangeRequest')}
          label={'lastPasswordChangeRequest'}
        />
        <FunctionField
          source="lastPasswordChanged"
          render={createDateRenderer('lastPasswordChanged')}
          label={'lastPasswordChanged'}
        />
        <FunctionField source="lastMFAPassed" render={createDateRenderer('lastMFAPassed')} label={'lastMFAPassed'} />
        <FunctionField source="lastActivity" render={createDateRenderer('lastActivity')} label={'lastActivity'} />

        <BooleanField source="isMFARequired" label={'isMFARequired'} />
        <BooleanField source="isLoginRequired" label={'isLoginRequired'} />
      </SimpleShowLayout>
    </Grid>
  </Grid>
);

export const AuthStateDetailsTab = () => {
  const params = useParams();
  const record = useRecordContext();

  const userId = params.userId ?? record?.id;
  if (!userId) {
    return <Empty />;
  }

  return (
    <Card>
      <CardContent>
        <Show resource="authstate" id={userId} actions={<Actions />}>
          <AuthStateDetailsForm />
        </Show>
      </CardContent>
    </Card>
  );
};
